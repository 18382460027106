import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./blog-post.css"

const BlogPost = ({ data }: { data: any }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <SEO title="pcostesi.get('blog')" />
      <article className="blogpost--article">
        <h1>{post.frontmatter.name}</h1>
        <div
          className="blogpost--time-to-read"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>
            Posted by {post.frontmatter.author} on {post.frontmatter.date}
          </span>
          <span>Time to read: {post.timeToRead} minutes</span>
        </div>
        <hr className="blogpost--hr" />
        <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
      </article>
    </Layout>
  )
}

export default BlogPost

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { permalink: { eq: $path } }) {
      html
      frontmatter {
        permalink
        name
        author
        date
      }
      timeToRead
    }
  }
`
